import {IconButton} from '@mui/material';
import IPhoneLocationIcon from '@mui/icons-material/NearMe';
import IPhoneLocationOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import AndroidLocationIcon from '@mui/icons-material/MyLocation';

import React, {MouseEventHandler} from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {isIOS} from '../utilities/ios-hacks';
import {useSelector} from 'react-redux';
import {getCurrentMapActionSelector} from '../store/mapControlSlice';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        locationIcon: {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.background.paper,
            padding: 10,

            '&:hover': {
                backgroundColor: theme.palette.background.paper,
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: theme.palette.background.paper,
                },
            },
        },
        root: {
            marginRight: 8,
            marginBottom: 4,
        }
    }));

interface UserLocationButtonProps {
    onClick?: MouseEventHandler,
}

function UserLocationButton({onClick}: UserLocationButtonProps): JSX.Element {
    const classes = useStyles();

    const mapIsOnUserLocation = useSelector(getCurrentMapActionSelector)?.type === 'trackUserLocation';

    const TrackingLocationIcon = isIOS() ? IPhoneLocationIcon : AndroidLocationIcon;
    const NotTrackingLocationIcon = isIOS() ? IPhoneLocationOutlinedIcon : AndroidLocationIcon;

    const icon = mapIsOnUserLocation ? <TrackingLocationIcon color={'primary'}/> : <NotTrackingLocationIcon color={'secondary'}/>;

    return <div className={classes.root} onClick={onClick}>
        <IconButton aria-label="go to the user's location" size={'medium'} className={classes.locationIcon}>
            {icon}
        </IconButton>
    </div>;
}

export default UserLocationButton;