import {Badge, BottomNavigation, BottomNavigationAction, Divider} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import PublicIcon from '@mui/icons-material/Public';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { randomCuisineSelected} from '../store/venuesSlice';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {activeFilterCountSelector, anyFilterActiveSelector} from '../store/searchFilters';
import {useLocation, useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 1299,
            backgroundColor: theme.palette.background.paper
        },
        bottomNavigation: {
            marginBottom: 3
        },
        badge: {
            height: 16,
            minWidth: 16,
            width: 16,
        },
        anchorOriginTopRightRectangle: {
            transform: 'scale(1) translate(50%, -35%)',
        }
    }));

interface Props {
    className: string
}

function BottomNav({className: externalClassName}: Props): JSX.Element {
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const filterBoxOpen = location.pathname === '/filters';
    const anyFilterActive = useSelector(anyFilterActiveSelector);
    const activeFilterCount = useSelector(activeFilterCountSelector);

    function randomCuisineOnClickHandler() {
        dispatch(randomCuisineSelected());
    }

    async function cuisinesOnClickHandler() {
        await navigate('/cuisines');
    }

    async function filterOnClickHandler() {
        if (filterBoxOpen) {
            await navigate('/');
        } else {
            await navigate('/filters');
        }
    }

    return (
        <div className={externalClassName + ' ' + classes.root}>
            <Divider/>
            <BottomNavigation
                showLabels
                value={anyFilterActive ? 'Filter' : undefined}
                className={classes.bottomNavigation}
            >
                <BottomNavigationAction label="Cuisines" onClick={cuisinesOnClickHandler} icon={<FormatListBulletedIcon />} />
                <BottomNavigationAction label="Random Cuisine" onClick={randomCuisineOnClickHandler} icon={<PublicIcon />} />
                <BottomNavigationAction label="Filter" value={'Filter'} onClick={filterOnClickHandler} icon={
                    <Badge classes={{
                        badge: classes.badge,
                        anchorOriginTopRightRectangular: classes.anchorOriginTopRightRectangle
                    }} badgeContent={activeFilterCount} color="primary">
                        <TuneIcon />
                    </Badge>
                } />
            </BottomNavigation>
        </div>
    );
}

export default BottomNav;