import {cuisineSearch, GroupedCuisineSearchResult, SecondaryCuisineSearchResult} from './cuisines';
import {locationSearch, LocationSearchComponent, LocationSearchResult} from './location';
import Fuse from 'fuse.js';
import {Cuisine} from '../../shared-types/responses';
import {tagSearch, TagSearchComponent, TagSearchResult} from './tags';

export type SearchResult = LocationSearchResult | GroupedCuisineSearchResult | TagSearchResult

export function combinedSearch(
    searchString: string,
    cuisineSearchEngine: Fuse<Cuisine>,
    locationSearchEngine: Fuse<LocationSearchComponent>,
    tagSearchEngine: Fuse<TagSearchComponent>
): SearchResult[] {
    const numberOfResults = 20;
    const locationSearchResults = locationSearch(searchString, numberOfResults, locationSearchEngine);
    
    const tagSearchResults = tagSearch(searchString, numberOfResults, tagSearchEngine);

    const groupedCuisineSearchResults = cuisineSearch(cuisineSearchEngine, searchString, numberOfResults);

    const combinedResults = [
        ...locationSearchResults, 
        ...groupedCuisineSearchResults,
        ...tagSearchResults
    ].sort(({score: scoreA = 1}, {score: scoreB = 1}) => {
        return scoreA - scoreB;
    });

    const topResults = [] as SearchResult[];
    let resultLengths = 0;

    while (resultLengths < numberOfResults && topResults.length < combinedResults.length) {
        const newItem = combinedResults[topResults.length] as SearchResult & {secondaries?: SecondaryCuisineSearchResult[]};
        topResults.push(newItem);
        const secondaryItemCount = newItem.secondaries?.length;
        const count = (secondaryItemCount || 0) >= 1 ? (secondaryItemCount || 0) + 1 : 1;
        resultLengths += count;
    }

    return topResults;
}