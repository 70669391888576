import React, {useEffect} from 'react';
import {Routes, Route, useNavigate} from 'react-router-dom';
import {CuisineDescriptionPage} from './map-display/cuisine-list-page/CuisineDescriptionPage';
import App from './App';
import {CuisineListPage} from './map-display/cuisine-list-page/CuisineListPage';
import {VenueDrawer} from './map-display/venue-drawer/VenueDrawer';
import {FilterBox} from './map-display/filter-box/FilterBox';
import {PageTree} from './utilities/PageTree';
import {TagResult} from './map-display/TagResult';
import {getCuisineUrl, getLegacyQueryParams} from './store/urlManagement';

function PageRouter(): JSX.Element {
    const navigate = useNavigate();

    useEffect(() => {
        const {cuisine, venuePlaceId} = getLegacyQueryParams();

        // Legacy query param compatibility
        if (location.pathname === '/') {
            if (venuePlaceId) {
                navigate('/venue/' + venuePlaceId);
            } else {
                navigate(getCuisineUrl(cuisine || undefined));
            }
        }
    }, []);

    return <Routes>
        <Route path="/" element={<App/>}>
            <Route path="/cuisines" element={<CuisineListPage/>}/>
            <Route path="/filters" element={<FilterBox/>}/>
            <Route path="/venue/:placeId" element={<VenueDrawer/>}/>
            <Route path="/tag/:tag" element={<TagResult/>}/>
            <Route path="/cuisines/:primaryCuisine" element={<CuisineDescriptionPage/>}/>
            <Route path="/cuisines/:primaryCuisine/:secondaryCuisine" element={<CuisineDescriptionPage/>}/>
        </Route>
        <Route path='/pagetree' element={<PageTree/>} />
    </Routes>;
}

export {
    PageRouter
};

