import {createSelector} from '@reduxjs/toolkit';
import {getAllVenuesSelector} from '../venuesSlice';
import Fuse from 'fuse.js';
import {SearchResultParent} from './SearchResultParent';

const options = {
    includeScore: true,
    threshold: 0.4,
    keys: ['value']
};

function deduplicateStrings(strings: string[]): string[] {
    const map = new Map<string, string>();

    for (const str of strings) {
        const strippedStr = str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

        if (!map.has(strippedStr)) {
            map.set(strippedStr, str);
        } else {
            const existingStr = map.get(strippedStr) || '';
            if (str.length > existingStr.length || (str.length === existingStr.length && countCapitals(str) > countCapitals(existingStr))) {
                map.set(strippedStr, str);
            }
        }
    }

    return [...map.values()];
}

function countCapitals(str: string): number {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str[i].match(/[A-Z]/)) {
            count++;
        }
    }
    return count;
}

const getAllTags = createSelector(
    [getAllVenuesSelector],
    (allVenues) => {

        const tagSet = new Set<string>(deduplicateStrings(allVenues?.flatMap((venue) => {
            return venue.tags || [];
        }) || []));

        const cuisineSet = new Set<string>(allVenues?.flatMap((venue) => {
            return venue.cuisines.flatMap((cuisine) => [
                cuisine.primary.toLowerCase(),
                ...cuisine.secondary ? [cuisine.secondary.toLowerCase()] : []
            ]);
        }));

        return [...tagSet.values()].filter((tag) => !cuisineSet.has(tag));
    });

const tagIndexSelector = createSelector(
    [getAllTags],
    (tagCandidates) => {

        return Fuse.createIndex(options.keys, tagCandidates.map((tag) => ({value: tag})));
    }
);

export const tagSearchEngineSelector = createSelector(
    [tagIndexSelector, getAllTags],
    (index, locationCandidates) => {
        return new Fuse(
            locationCandidates.map((tag) => ({value: tag})),
            options,
            index
        );
    }
);

export interface TagSearchComponent {
    value: string
}

export interface TagSearchResult extends SearchResultParent<TagSearchComponent> {
    score: number,
    item: { value: string },
    refIndex: number,
    key: string,
    type: 'TagSearchResult',
}

export function tagSearch(searchString: string, numResults: number, tagSearchEngine: Fuse<TagSearchComponent>): TagSearchResult[] {
    return tagSearchEngine.search(searchString)
        .filter((result) =>
            result && result.score && result.score < 0.4
        )
        .slice(0, numResults)
        .map(({score = 1, item, refIndex}) => ({
            score, item, refIndex, key: item.value + '-tag-key', type: 'TagSearchResult'
        }));
}