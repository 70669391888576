import {Action, combineReducers} from 'redux';
import venuesReducer from './venuesSlice';
import {ThunkAction} from 'redux-thunk';
import {configureStore} from '@reduxjs/toolkit';
import {geolocationReducer} from './geolocationSlice';
import {uiReducer} from './uiSlice';
import {mapControlReducer} from './mapControlSlice';
import {createBrowserHistory} from 'history';
import {createReduxHistoryContext} from 'redux-first-history';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

export const store = createStore();

export const theHistory = createReduxHistory(store);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createStore() {
    return configureStore({
        reducer: combineReducers({
            router: routerReducer,
            mapData: venuesReducer,
            geoLocationData: geolocationReducer,
            uiState: uiReducer,
            mapControl: mapControlReducer,
        }),
        middleware: (getDefaultMiddleware) =>
            [
                ...getDefaultMiddleware({
                    serializableCheck: false,
                    immutableCheck: false,
                }),
                routerMiddleware
            ],
    });
}


export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;