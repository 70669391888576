import {
    Cuisine,
    DeprecatedVegoStatus,
    DetailedCuisine,
    NewVegoStatus,
    VegoStatus,
    VenueResponse
} from '../shared-types/responses';

function validateVegoStatus(str: string): VegoStatus | undefined {
    if (!(str in NewVegoStatus || str in DeprecatedVegoStatus)) {
        return undefined;
    }
    return str as VegoStatus;
}

export function getVenueVegoStatus(venue: VenueResponse, detailedCuisines: Map<string, Cuisine & Partial<DetailedCuisine>>): VegoStatus | undefined {
    if (venue.vegoStatus) {
        return venue.vegoStatus;
    }

    const vegoStatuses = venue.cuisines.flatMap((cuisine) => {
        const statuses: string[] = [];
        if (cuisine.secondary) {
            const secondaryDetailedCuisine = detailedCuisines.get(cuisine.secondary);
            if (secondaryDetailedCuisine?.vegetarianStatus) {
                statuses.push(secondaryDetailedCuisine.vegetarianStatus);
            }
        }

        const primaryDetailedCuisine = detailedCuisines.get(cuisine.primary);
        if (primaryDetailedCuisine?.vegetarianStatus) {
            statuses.push(primaryDetailedCuisine.vegetarianStatus);
        }

        return statuses;
    });

    if (vegoStatuses.length > 0) {
        return validateVegoStatus(vegoStatuses[0]);
    }

    return undefined;
}