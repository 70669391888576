import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
    createStyles({
        iosImage: {
            width: 18,
        },
    }));

export default function IosShareIcon(): JSX.Element {
    const classes = useStyles();
    return <img
        alt={'Share'}
        className={classes.iosImage}
        src={'/ios-share.png'}
    />;
}