import React, {useLayoutEffect} from 'react';
import {Helmet} from 'react-helmet';
import {searchStringSet} from '../store/uiSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getTagSelector, tagSelected} from '../store/venuesSlice';

function TagResult(): JSX.Element {
    const dispatch = useDispatch();
    const {tag} = useParams<{tag: string}>();
    const tagInState = useSelector(getTagSelector);

    React.useEffect(
        () => {
            if (!tag) return;
            if (tag !== tagInState) {
                dispatch(tagSelected({
                    tag,
                    fitMapToCuisine: true
                }));
            }
        },
        [tag]
    );

    useLayoutEffect(() => {
        if (tagInState) {
            dispatch(searchStringSet(tagInState));
        } else {
            dispatch(searchStringSet(''));
        }
    }, [tagInState]);

    if (!tag) {
        console.warn('Tried to visit invalid tag');
    }

    return <>
        <Helmet>
            <title>{tag + ' places in Sydney'}</title>
            <meta
                name="description"
                content={'Where to find ' + tag + ' places in Sydney'}
            />
        </Helmet>
    </>;
}

export {
    TagResult,
};