import React from 'react';
import { DialogContent, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {fbLink, instaLink, newsletterSignupLink, patreonLink} from '../utilities/links';
import AppStoreBadges from '../prototype/AppStoreBadges';
import {PatreonButton} from './PatreonButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
        dialogContent: {
            fontSize: '0.9rem',
        },
        welcomeImage: {
            width: '100%',
        }
    }),
);

function FollowTabContent(): JSX.Element {
    const classes = useStyles();

    return <div>
        <DialogContent dividers className={classes.dialogContent}>
            <Typography className={classes.paper} gutterBottom>
                Hi. If you’d like to talk to us about the map or anything really, reach out with any of the below links. We’ll be using Facebook and Instagram to update you on upcoming improvements to the map, future events and all upcoming content. Have a look at stories on either platform for those details.
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                <a href={instaLink} target="_blank" rel="noopener noreferrer">Instagram</a>
                <br/>
                <a href={fbLink} target="_blank" rel="noopener noreferrer">facebook</a>
            </Typography>
            <Typography className={classes.paper}>
                If you’re on mobile, try our apps:
                <AppStoreBadges/>
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                If you’d like to help us improve Have You Eaten, or just contribute to our running costs, check out our <a href={patreonLink} target="_blank" rel="noopener noreferrer">Patreon</a>.
                <br/>
                <PatreonButton/>
            </Typography>
            <Typography className={classes.paper} gutterBottom>
                Click <a href={newsletterSignupLink} target="_blank" rel="noopener noreferrer">here</a> to sign up to our newsletter.
            </Typography>
        </DialogContent>
    </div>;
}

export default FollowTabContent;