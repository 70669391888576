import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IconButton, Typography} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import {useSelector} from 'react-redux';
import {AutocompleteGroupedOption, CuisineList} from './CuisineList';
import {Cuisine} from '../../shared-types/responses';
import {useNavigate} from 'react-router-dom';
import {getCuisinesSelector} from '../../store/getMergedDetailedCuisinesSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        content: {
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cuisines: {
            overflow: 'scroll'
        }
    })
);

function getGroupedOptions(cuisines?: Cuisine[]): AutocompleteGroupedOption<Cuisine>[] {
    return cuisines?.reduce((acc, option, index) => {
        const group = option.primary;

        if (acc.length > 0 && acc[acc.length - 1].group === group) {
            acc[acc.length - 1].options.push(option);
        } else {
            acc.push({
                key: index,
                index,
                group,
                options: [option],
            });
        }

        return acc;
    }, [] as AutocompleteGroupedOption<Cuisine>[]) || [];
}
function CuisineListPageContent(): JSX.Element {
    const classes = useStyles();
    const navigate = useNavigate();
    const allCuisines = useSelector(getCuisinesSelector);

    const groupedOptions: AutocompleteGroupedOption<Cuisine>[] = getGroupedOptions(allCuisines);

    return (
        <div className={classes.root}>
            <MuiDialogTitle>
                <Typography variant="h6">
                            Cuisines
                </Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => {navigate('/');}}
                    size="large">
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <Divider/>
            <CuisineList
                className={classes.cuisines}
                groupedOptions={groupedOptions}
                listboxProps={[]}
                size={'full'}
            />
        </div>
    );
}

export {
    CuisineListPageContent,
};