import {Cuisine, DetailedCuisine} from '../shared-types/responses';
import {createSelector} from 'reselect';
import {RootState} from './store';

export const getCuisinesSelector: (state: RootState) => Cuisine[] | undefined = (state: RootState) => state.mapData.cuisines;
export const getDetailedCuisinesSelector: (state: RootState) => DetailedCuisine[] | undefined = (state: RootState) => state.mapData.cuisineDetails;

export type DetailedCuisines = Map<string, (Cuisine & Partial<DetailedCuisine>)>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getMergedDetailedCuisinesSelector: (state: RootState) => DetailedCuisines = createSelector<
    RootState,
    Cuisine[] | undefined,
    DetailedCuisine[] | undefined,
    Map<string, (Cuisine & Partial<DetailedCuisine>)>
        >(
        [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getCuisinesSelector,
            getDetailedCuisinesSelector
        ],
        (cuisines: Cuisine[], detailedCuisines: DetailedCuisine[]) => {

            if (!detailedCuisines) {
                return [];
            }

            function getCuisineKey(cuisineName: string) {
                return cuisineName.replace(/\s/g, '').toLowerCase().trim();
            }

            const detailedCuisineMapPairs: [string, DetailedCuisine][] = detailedCuisines.map((detailedCuisine: DetailedCuisine) => {
                return [getCuisineKey(detailedCuisine.title), detailedCuisine];
            });

            const detailedCuisineMap = new Map(detailedCuisineMapPairs);

            const result: [string, (Cuisine & Partial<DetailedCuisine>)][] | undefined = cuisines?.map((cuisine: Cuisine) => {
                const simpleCuisineKey = cuisine.secondary ? cuisine.secondary : cuisine.primary;
                const detailedCuisine: DetailedCuisine | undefined = detailedCuisineMap.get(getCuisineKey(
                    cuisine.secondary ? cuisine.secondary : cuisine.primary
                ));
                return [simpleCuisineKey, {
                    ...cuisine,
                    ...detailedCuisine
                }];
            });

            const defaultCuisine: Cuisine & Partial<DetailedCuisine> = {
                content: detailedCuisineMap.get('default')?.content || 'More information coming soon',
                primary: 'default',
            };


            return new Map([
                ...(result || []),
                ['default', defaultCuisine]
            ]);
        }
        );