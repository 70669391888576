import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            // -webkit-box-align: center;
            alignItems: 'center',
            backfaceVisibility: 'hidden',
            backgroundColor: 'rgb(255, 66, 77)',
            borderRadius: '9999px',
            border: '1px solid rgb(255, 66, 77)',
            boxSizing: 'border-box',
            display: 'inline-flex',
            fontWeight: 500,
            height: 32,
            marginTop: 6,
            marginBottom: 6,
            // -webkit-box-pack: center;
            justifyContent: 'center',
            padding: '0.46875rem 1rem',
            position: 'relative',
            // pointer-events: unset;
            textAlign: 'center',
            textDecoration: 'none',
            textTransform: 'none',
            transition: 'all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s',
            userSelect: 'none',
            whiteSpace: 'unset',
            // width: '100%',
            // width: '11.5rem',
            color: 'rgb(255, 255, 255) !important',
            fontSize: 15,
        },
        innerIcon: {
            width: '1rem',
            height: '1rem',
            display: 'flex',
            fill: 'rgb(255,255,255)',
        },
        svg: {
            width: '1rem',
            height: '1rem',
            fill: 'rgb(255,255,255)',
        },
        innerBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        generalFlex: {
            display: 'inline-flex',
            alignSelf: 'center',
            alignItems: 'center',
            paddingRight: 2,
        }
    }),
);

interface PatreonButtonProps {
    buttonText?: string
}

function PatreonButton({buttonText = 'Become a patron'}: PatreonButtonProps): JSX.Element {
    const classes = useStyles();
    
    return <a className={classes.root} color="primary" tabIndex={0} type="button"
        href="https://www.patreon.com/bePatron?u=48253702" role="button" target="_blank" rel="noopener noreferrer">
        <div className={classes.innerBox}>
            <div className={classes.generalFlex}>
                <span className={classes.innerIcon}>
                    <svg className={classes.svg} viewBox="0 0 569 546"
                        xmlns="http://www.w3.org/2000/svg"><g><circle
                            cx="362.589996" cy="204.589996" data-fill="1" id="Oval" r="204.589996"></circle><rect data-fill="2"
                            height="545.799988"
                            id="Rectangle"
                            width="100" x="0"
                            y="0"></rect></g></svg></span>
            </div>
            &nbsp; {buttonText}
        </div>
    </a>;
}

export {
    PatreonButton
};