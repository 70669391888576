import React from 'react';
import {getAllVenuesSelector} from '../store/venuesSlice';
import { useSelector } from 'react-redux';
import {getCuisineUrl} from '../store/urlManagement';
import {Cuisine, DetailedCuisine} from '../shared-types/responses';
import {getMergedDetailedCuisinesSelector} from '../store/getMergedDetailedCuisinesSelector';

// Hack - this is provided by the backend. If it changes the pagetree will break.
// Thankfully the pagetree is collected from this page by hand, so this should be spotted if it happens.
const DEFAULT_VENUE_BLURB = 'Hang tight! We’ve got a blurb with details on the restaurant and what to order coming.';

function PageTree(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const allDetailedCuisines: Map<string, Cuisine & Partial<DetailedCuisine>> = useSelector(getMergedDetailedCuisinesSelector);
    const allVenues = useSelector(getAllVenuesSelector);

    const venuesWithDescriptions = allVenues?.filter((venue) =>
        venue.multiLineBlurb &&
        venue.multiLineBlurb.length > 10 &&
        venue.multiLineBlurb !== DEFAULT_VENUE_BLURB
    );

    const venueLinks = venuesWithDescriptions?.map((venue) => {
        return `<url>
            <loc>https://haveyoueaten.co/venue/${venue.googlePlaceId}</loc>
        </url>`;
    });

    const cuisineLinks = [...allDetailedCuisines?.entries()].filter(([key, cuisine]) => {
        return cuisine.content && cuisine.content.trim().length > 0 &&
            key !== 'default';
    }).map(([_key, cuisine]) => {
        return `<url>
            <loc>https://haveyoueaten.co${getCuisineUrl(cuisine)}</loc>
        </url>`;
    });

    const pagetree = `
    <?xml version="1.0" encoding="UTF-8"?>\n
        <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            ${cuisineLinks?.join('\n')}\n
            ${venueLinks?.join('\n')}
        </urlset>
    `;

    return <code>
        {pagetree}
    </code>;
}

export {
    PageTree
};