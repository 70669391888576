import React from 'react';
import { Button, ListItem, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Cuisine} from '../../shared-types/responses';
import {getCuisineUrl} from '../../store/urlManagement';
import {openCuisineDrawer} from '../../store/venuesSlice';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

interface Props {
    cuisine: Cuisine
}

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            paddingLeft: 10,
            lineHeight: 1,
            paddingTop: 6,
            paddingBottom: 6,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
            boxSizing: 'border-box',
            outline: '0',
            WebkitTapHighlightColor: 'transparent',
        },
        cuisineButton: {
            width: '100%',
            textTransform: 'none',
            display: 'flex',
            justifyContent: 'flex-start',
        }
    }),
);

function CuisineListSecondaryCuisineOptionComponent({cuisine}: Props): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return <ListItem
        className={classes.listItem}
        onClick={async () => {
            const newRoute = getCuisineUrl(cuisine);
            dispatch(openCuisineDrawer());
            await navigate(newRoute);
        }}
    >
        <Button
            color="primary"
            variant="text"
            className={classes.cuisineButton}
            size="small"
        >
            <Typography noWrap>
                {cuisine.secondary}
            </Typography>
        </Button>

    </ListItem>;
}

const CuisineListSecondaryCuisineOption = React.memo(
    CuisineListSecondaryCuisineOptionComponent,
    ((prevProps, nextProps) => {
        return prevProps.cuisine.secondary == nextProps.cuisine.secondary &&
            prevProps.cuisine.primary == nextProps.cuisine.primary;
    })
);

export {
    CuisineListSecondaryCuisineOption,
};