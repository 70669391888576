const ANONYMOUS_USER_COOKIE_KEY = 'anonymous_user';
interface Cookie {
    key: string;
    value: string;
}

export interface AnonymousUser {
    id: string,
}

function cookieFromString(cookie: string): Cookie {
    const [key, value] = cookie.split('=', 2);
    return {key, value} as Cookie;
}

function getCookies(): Cookie[] {
    return (document.cookie || '')
        .split('; ')
        .map(cookieFromString);
}

function getAnonymousUserDetails(): AnonymousUser | undefined {
    const anonymousUserCookie: Cookie | undefined = getCookies()
        .find((cookie) => cookie.key === ANONYMOUS_USER_COOKIE_KEY);

    if (!anonymousUserCookie) {
        return undefined;
    }

    return JSON.parse(decodeURIComponent(anonymousUserCookie.value)) as AnonymousUser;
}
function setAnonymousUserDetails(anonymousUser: AnonymousUser): void {
    document.cookie = ANONYMOUS_USER_COOKIE_KEY + '=' + encodeURIComponent(JSON.stringify(anonymousUser)) + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
}

export type Platform = 'Cordova' | 'Browser';
const PLATFORM_COOKIE_KEY = 'platform';

function getPlatform(): Platform {
    return getCookies()
        .find((cookie) => cookie.key === PLATFORM_COOKIE_KEY)
        ?.value as Platform | undefined || 'Browser';
}

function setPlatform(platform: Platform): void {
    document.cookie = PLATFORM_COOKIE_KEY + '=' + platform + '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
}

export {
    getPlatform,
    setPlatform,
    getAnonymousUserDetails,
    setAnonymousUserDetails,
};