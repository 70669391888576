import React, {useEffect, useState} from 'react';
import './App.css';
import { MainMapDisplay } from './map-display/MainMapDisplay';
import {createUseStyles} from 'react-jss';
import {UiOverlay} from './map-display/UiOverlay';
import {IntroModal} from './prototype/IntroModal';
import {getFirstTimeUser, sleep} from './utilities/misc-utilities';
import { LoadScript } from '@react-google-maps/api';
import config from './config';
import {useDispatch, useSelector} from 'react-redux';
import {getUiOverlayEnabledSelector} from './store/mapControlSlice';
import {Helmet} from 'react-helmet';
import {closeCuisineDrawer, getCuisineDrawerOpenSelector} from './store/venuesSlice';
import {Outlet, useLocation} from 'react-router-dom';

const useStyles = createUseStyles({
    app: {
        display: 'flex',
        flexDirection: 'column',
    }
});

function App(): JSX.Element {
    const classes = useStyles();
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [introModalShown, setIntroModalShown] = useState(false);
    const uiOverlayEnabled = useSelector(getUiOverlayEnabledSelector);
    const location = useLocation();
    const cuisineDrawerOpen = useSelector(getCuisineDrawerOpenSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (cuisineDrawerOpen && !location.pathname.includes('cuisine')) {
            dispatch(closeCuisineDrawer());
        }
    }, [location]);

    useEffect(() => {
        const isFirstTimeUser = getFirstTimeUser();
        if (!introModalShown) {
            if (isFirstTimeUser === undefined) {
                console.error('First time user is undefined, so showing the intro modal by default. This should never happen');
                setIntroModalOpen(true);
                setIntroModalShown(true);
            } else if (isFirstTimeUser) {
                setIntroModalOpen(true);
                setIntroModalShown(true);
            }
        }
    });
    
    return (
        <div className={classes.app}>
            <Helmet>
                <title>Have You Eaten</title>
                <meta
                    name="description"
                    content="An interactive map of Sydney cuisines. Search for Sichuan, Sudanese, Southern Thai food, or any other cuisine, and find restaurants popular in those communities."
                />
            </Helmet>
            <LoadScript googleMapsApiKey={config.google.maps.accessToken}>
                <MainMapDisplay/>
            </LoadScript>
            <IntroModal
                open={introModalOpen}
                handleClose={async () => {
                    setIntroModalOpen(false);
                    await sleep(350);
                }}
            />
            {uiOverlayEnabled && <UiOverlay/>}
            <Outlet />
        </div>
    );
}

export default App;
