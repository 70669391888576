import React from 'react';
import { Drawer, Hidden } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {CuisineDescriptionPageContent} from './CuisineDescriptionPageContent';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector} from 'react-redux';
import {
    cuisineSelected,
    getCuisineDrawerOpenSelector
} from '../../store/venuesSlice';
import {htmlToPlainText} from '../../utilities/misc-utilities';
import {Cuisine, DetailedCuisine} from '../../shared-types/responses';
import {useParams} from 'react-router-dom';
import {getMergedDetailedCuisinesSelector} from '../../store/getMergedDetailedCuisinesSelector';

const useStyles = makeStyles(() =>
    createStyles({
        desktopDrawer: {
            height: '100%'
        },
    }),
);

type PathParams = {
    primaryCuisine: string,
    secondaryCuisine?: string,
}

function CuisineDescriptionPage(): JSX.Element {
    const classes = useStyles();
    const {primaryCuisine, secondaryCuisine} = useParams<PathParams>();
    const allDetailedCuisines: Map<string, Cuisine & Partial<DetailedCuisine>> = useSelector(getMergedDetailedCuisinesSelector);
    const dispatch = useDispatch();

    const cuisineDrawerOpen = useSelector(getCuisineDrawerOpenSelector);
    
    React.useEffect(() => {
        if (!primaryCuisine) return;
        dispatch(cuisineSelected({
            cuisine: {
                primary: primaryCuisine, 
                secondary: secondaryCuisine
            }}
        ));
    },
    [primaryCuisine, secondaryCuisine]
    );

    if (!primaryCuisine) {
        return <>Error. Invalid cuisine link</>;
    }

    if (!cuisineDrawerOpen) {
        return <></>;
    }

    const {title: detailedTitle, content: detailedContent} = allDetailedCuisines.get(secondaryCuisine || primaryCuisine || 'default') || {};
    const cuisineText = detailedTitle || secondaryCuisine || primaryCuisine;

    const pageTitle = `${cuisineText} food in Sydney - Have You Eaten`;

    return <>
        <Helmet>
            <title>{pageTitle}</title>
            {detailedContent && <meta
                name="description"
                content={htmlToPlainText(detailedContent)}
            />}
        </Helmet>
        <Hidden mdDown> {/* Desktop like */}
            <Drawer
                anchor={'left'}
                open={true}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        width: '25%'
                    }
                }}
            >
                <div className={classes.desktopDrawer}>
                    <CuisineDescriptionPageContent
                        primaryCuisine={primaryCuisine}
                        secondaryCuisine={secondaryCuisine}
                    />
                </div>
            </Drawer>
        </Hidden>
        <Hidden mdUp> {/* Mobile like */}
            <CuisineDescriptionPageContent
                primaryCuisine={primaryCuisine}
                secondaryCuisine={secondaryCuisine}
            />
        </Hidden>
    </>;
}

export {
    CuisineDescriptionPage,
};