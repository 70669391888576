import React from 'react';
import {useDispatch} from 'react-redux';
import {tagSelected} from '../../store/venuesSlice';
import {searchResultsClosed} from '../../store/uiSlice';
import {TagSearchResult} from '../../store/searchEngines/tags';
import {createStyles, makeStyles} from '@mui/styles';
import {ListItem, Typography} from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import {useNavigate} from 'react-router-dom';

interface Props {
    searchResult: TagSearchResult,
    className?: string,
}

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            justifyContent: 'space-between',
        },
        innerText: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            margin: 2,
            marginRight: 8,
        }
    }),
);

function TagBasedSearchResult({searchResult, className}: Props): JSX.Element {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    return <ListItem
        button
        className={[classes.listItem, className].join(' ')}
        key={searchResult.item.value}
        onClick={async () => {
            dispatch(tagSelected({
                tag: searchResult.item.value
            }));
            await navigate('/tag/' + searchResult.item.value);
            dispatch(searchResultsClosed());
        }}
        data-search-score={searchResult.score}
    >
        <div className={classes.innerText}>
            <TagIcon className={classes.icon} color={'secondary'}/>
            <Typography noWrap>
                {searchResult.item.value}
            </Typography>
        </div>
    </ListItem>;
}

export {
    TagBasedSearchResult,
};