import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
    createStyles({
        appleAppStore: {
            display: 'inlineBlock',
            overflow: 'hidden',
        },
        appleAppStoreInner: {
            borderRadius: 13,
            height: 60,
            border: '10px solid white',
        },
        appStoreLinkSection: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        googleAppStoreImage: {
            height: 60,
        }
    }),
);

function AppStoreBadges(): JSX.Element {
    const classes = useStyles();

    return <div className={classes.appStoreLinkSection}>
        <a href='https://play.google.com/store/apps/details?id=co.haveyoueaten.twa&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img className={classes.googleAppStoreImage} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
        </a>
        <a href="https://apps.apple.com/au/app/have-you-eaten/id1553862054?itsct=apps_box&amp;itscg=30200" className={classes.appleAppStore}>
            <img className={classes.appleAppStoreInner} src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1616371200&h=c84f695bd05171bf3219ce2bfeca27b1" alt="Download on the App Store"/>
        </a>
    </div>;
}

export default AppStoreBadges;