// This file should be copied to the frontend
import {LatLngLiteral, OpeningHours} from '@googlemaps/google-maps-services-js';

export interface DetailedCuisine {
    title: string,
    content: string,
    tags: string[],
    vegetarianStatus?: string,
}

interface SpreadsheetLoadStatus {
    startTime: number,
    sheetsLoaded: number,
    totalSheets: number
}

interface PlaceCacheStatus {
    totalVenues: number,
    totalLoaded: number
    totalStale: number
    totalMissing: number
}

export interface Cuisine {
    primary: string;
    secondary?: string;
}

export interface AllVenuesResponse {
    loadTime?: number;
    venues: VenueResponse[];
}

export type CuisineResponse = DetailedCuisine[];

export interface VenueResponse {
    location: LatLngLiteral;
    priceLevel?: PriceLevel;
    name: string;
    simpleAddress: string;
    openingHours?: OpeningHours;
    attributions: string[];
    multiLineBlurb: string | undefined;
    singleLineBlurb: string;
    cuisines: Cuisine[];
    googlePlaceId: string;
    website?: string;
    businessStatus?: string;
    googleUrl?: string;
    type?: string[];
    tags: string[];
    vegoStatus: VegoStatus | null;
    localPhoneNumber?: string;
    internationalPhoneNumber?: string;
}

export enum NewVegoStatus {
    Bad = 'Bad',
    Struggle = 'Struggle',
    Medium = 'Medium',
    Easy = 'Easy',
    WholeMenu = 'WholeMenu',
}

export type VegoStatus = DeprecatedVegoStatus | NewVegoStatus;
export enum DeprecatedVegoStatus {
    VeganOnly = 'VeganOnly',
    VeganFriendly = 'VeganFriendly',
    VegetarianOnly = 'VegetarianOnly',
    VegetarianFriendly = 'VegetarianFriendly',
    Meater = 'Meater',
}

export interface ProtoTypeAccessCookieJson {
    // in ms since epoch
    accessUntil: number;
    code: string;
}

export interface SubscriptionResponse {
    status: 'NOT_FOUND' | 'NO_ACCESS' | 'HAS_ACCESS';
}

export interface ReadinessResponse {
    error: string,
    sheetLoadedStatus: {
        venues: boolean,
        subscriptions: boolean,
    }
}

export interface StatusResponse {
        venues: boolean,
        feedback: boolean,
}

export interface StatusResponseV2 {
    placeDetails: PlaceCacheStatus,
    venueSpreadsheetStatus: {
        loaded: boolean,
        loadTime?: number,
        nextLoad?: SpreadsheetLoadStatus
    }
}

export type PriceLevel = 0 | 1 | 2 | 3 | 4;