import {GoogleMap} from '@react-google-maps/api';
import React, {useState} from 'react';
import {getSearchedCuisineSelector, getTagSelector} from '../store/venuesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {createUseStyles} from 'react-jss';
import {useMediaQuery} from '@mui/material';
import theme from '../theme';
import {
    getGeolocationTheoreticallySupported,
    setupGeolocation
} from '../store/geolocationSlice';
import MapControlWrapper from './MapControlWrapper';
import UserLocationButton from './UserLocationButton';
import {MainMapManipulator} from './MainMapManipulator';
import {mapActionCompleted, mapActionTriggered} from '../store/mapControlSlice';
import {getCuisineUrl} from '../store/urlManagement';
import {useLocation, useNavigate} from 'react-router-dom';
import {Cuisine} from '../shared-types/responses';

const useStyles = createUseStyles({
    map: {
        width: '100vw',
        flex: '1 0 33%'
    },
});

const centerOfSydney: google.maps.LatLngLiteral = {lng: 151.1031221,lat: -33.8708327};

function getNextPageUrl(currentCuisine: Cuisine | null, currentTag: string | undefined) {
    if (currentCuisine) {
        return getCuisineUrl(currentCuisine);
    }

    if (currentTag) {
        return '/tag/' + encodeURIComponent(currentTag);
    }

    return '/';
}

function MainMapDisplay(): JSX.Element {
    const geolocationSupported: boolean = useSelector(getGeolocationTheoreticallySupported);
    const displayZoomControls = useMediaQuery(theme.breakpoints.up('sm'));
    const currentCuisine = useSelector(getSearchedCuisineSelector);
    const currentTag = useSelector(getTagSelector);
    const currentLocation = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const classes = useStyles();
    const [map, setMap] = useState<google.maps.Map | null>(null);

    function onUserLocationButtonClick() {
        dispatch(setupGeolocation());
        dispatch(mapActionTriggered({type: 'trackUserLocation'}));
    }

    return <GoogleMap
        onLoad={(map) => {
            setMap(map);
        }}
        options={{
            disableDefaultUI: true,
            gestureHandling: 'greedy',
            restriction: {
                latLngBounds: {
                    north: -9.456152219,
                    south: -47.353928451,
                    east: 179.2852294445,
                    west: 112.8412902355,
                },
            },
            maxZoom: 21,
            minZoom: 6,
            styles: [{
                featureType: 'poi',
                elementType: 'labels',
                stylers: [{
                    visibility: 'off'
                }]
            }],
            clickableIcons: false,
            zoomControl: displayZoomControls
        }}
        onClick={async () => {
            // When the user clicks or taps on the map, they're generally navigating away from a drawer, so we need to
            // reset the url appropriately
            const nextPageUrl = getNextPageUrl(currentCuisine, currentTag);
            if (nextPageUrl !== currentLocation.pathname) {
                await navigate(nextPageUrl);
            }
        }}
        onDragStart={() => {
            dispatch(mapActionCompleted());
        }}
        mapContainerClassName={classes.map}
        zoom={13}
        center={centerOfSydney}
    >
        {map && <MainMapManipulator map={map}/>}
        {map && geolocationSupported ?
            <MapControlWrapper map={map} controlPosition={google.maps.ControlPosition.RIGHT_BOTTOM}>
                <UserLocationButton onClick={onUserLocationButtonClick}/>
            </MapControlWrapper>
            : null}
    </GoogleMap>;
}

export {
    MainMapDisplay
};