import React from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Dialog, Drawer, Hidden} from '@mui/material';
import {CuisineListPageContent} from './CuisineListPageContent';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        content: {
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        cuisines: {
            overflow: 'scroll'
        },
        desktopDrawer: {
            height: '100%'
        }
    })
);

function CuisineListPage(): JSX.Element {
    const classes = useStyles();
    const navigate = useNavigate();

    return <>
        <Hidden mdDown> {/* Desktop like */}
            <Drawer
                anchor={'left'}
                open={true}
                variant={'persistent'}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        width: '25%'
                    }
                }}
            >
                <div className={classes.desktopDrawer}>
                    <CuisineListPageContent/>
                </div>
            </Drawer>
        </Hidden>
        <Hidden mdUp> {/* Mobile like */}
            <Dialog fullScreen onClose={() => {navigate('/');}} aria-labelledby="filter-dialog" open={true}>
                <CuisineListPageContent/>
            </Dialog>
        </Hidden>
    </>;
}

export {
    CuisineListPage,
};