import Dialog from '@mui/material/Dialog';
import React, {useState} from 'react';
import {Box, Button, Checkbox, DialogTitle, FormControl, FormControlLabel, Slider, Typography, useMediaQuery} from '@mui/material';
import {assertTypeOrThrow, assertUnreachable} from '../../utilities/misc-utilities';
import {NewVegoStatus} from '../../shared-types/responses';

export interface AdvancedVegoDialogProps {
    open: boolean;
    onClose: (
        vegoScore: NewVegoStatus,
        includeUnrated: boolean,
        ) => void;
}
type InternalVegoStatusValue = 0 | 1 | 2 | 3;
const marks: { label: string; value: InternalVegoStatusValue}[] = [
    {
        value: 3,
        label: 'Only show venues with a vegetarian menu',
    },
    {
        value: 2,
        label: 'Include venues that are good for vegetarians',
    },
    {
        value: 1,
        label: 'Include venues that are alright for vegetarians',
    },
    {
        value: 0,
        label: 'Include venues with a limited experience for vegetarians',
    }
];

function AdvancedVegoDialog(props: AdvancedVegoDialogProps): JSX.Element {
    const { onClose, open } = props;

    const [vegoStatusValue, setVegoStatusValue] = useState<InternalVegoStatusValue>(2);
    const [includeUnrated, setIncludeUnrated] = useState<boolean>(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isLargeScreen = useMediaQuery('(min-width:601px)');

    function getSliderDescriptionWidth(): number {
        if (isLargeScreen) {
            return 325;
        }

        if (isSmallScreen) {
            return 225;
        }

        return 150;
    }


    function getVegoStatus(vegoStatusValue:InternalVegoStatusValue): NewVegoStatus {
        switch (vegoStatusValue) {
        case 3:
            return NewVegoStatus.WholeMenu;
        case 2:
            return NewVegoStatus.Easy;
        case 1:
            return NewVegoStatus.Medium;
        case 0:
            return NewVegoStatus.Struggle;
        default:
            assertUnreachable(vegoStatusValue);
        }
    }
    
    const handleClose = () => {
        onClose(getVegoStatus(vegoStatusValue), includeUnrated);
    };

    return (
        <Dialog onClose={handleClose} open={open} sx={{
            '& .MuiDialog-paper': {
                padding: 0.8
            }
        }}>
            <DialogTitle>Advanced vegetarian filter</DialogTitle>
            <FormControl component="fieldset">
                <Box sx={{
                    height: 290,
                    padding: 1,
                    paddingBottom: 3,
                    marginTop: 2,
                }}>
                    <Slider
                        sx={{
                            '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                            },
                            '& .MuiSlider-markLabel': {
                                whiteSpace: 'normal',
                                width: getSliderDescriptionWidth()
                            },
                            height: 230,
                            marginTop: 1.5
                        }}
                        orientation="vertical"
                        aria-label="Vegetarian friendliness"
                        defaultValue={0}
                        step={null}
                        track={'inverted'}
                        valueLabelDisplay="off"
                        value={vegoStatusValue}
                        onChange={(event, value, ) => setVegoStatusValue(assertTypeOrThrow(value, 0))}
                        marks={marks}
                        min={0}
                        max={3}
                    />
                </Box>
            </FormControl>
            <FormControlLabel
                control={<Checkbox />}
                label="Show places that we haven't yet rated for vegetarians"
                checked={includeUnrated}
                onChange={(event, checked) => setIncludeUnrated(checked)}
                sx={{
                    margin: 1,
                    marginTop: 2,
                    marginBottom: 2,
                }}
            />
            <Button
                color="primary"
                variant="contained"
                onClick={handleClose}
            >
                <Typography noWrap>
                    Apply
                </Typography>
            </Button>
        </Dialog>
    );
}

export {
    AdvancedVegoDialog
};