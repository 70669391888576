import React from 'react';
import {Hidden} from '@mui/material';
import {FilterBoxDrawerDesktop} from '../filter-box/FilterBoxDrawerDesktop';
import {FilterBoxDialogMobile} from '../filter-box/FilterBoxDialogMobile';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        filterBox: {
            pointerEvents: 'auto',
        },
    }),
);


function FilterBox(): JSX.Element {
    const classes = useStyles();
    const navigate = useNavigate();
    
    return <>
        <Hidden mdDown>
            <FilterBoxDrawerDesktop className={classes.filterBox} open={true}/>
        </Hidden>
        <Hidden mdUp>
            <FilterBoxDialogMobile open={true} handleClose={() => navigate('/')}/>
        </Hidden>
    </>;
}

export {
    FilterBox
};