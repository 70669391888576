import React, {useState} from 'react';
import {Divider, IconButton, List, ListItem, ListItemText, Snackbar, Typography} from '@mui/material';
import { Theme, Theme as DefaultTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import marked from 'marked';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import PhoneIcon from '@mui/icons-material/Phone';
import PublicIcon from '@mui/icons-material/Public';
import AndroidShareIcon from '@mui/icons-material/Share';
import {isIOS} from '../../utilities/ios-hacks';
import IosShareIcon from '../../icons/IosShareIcon';
import copy from 'copy-to-clipboard';
import MuiAlert from '@mui/material/Alert';
import {Property} from 'csstype';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {DeprecatedVegoStatus, NewVegoStatus, PriceLevel, VegoStatus, VenueResponse} from '../../shared-types/responses';
import {openCuisineDrawer} from '../../store/venuesSlice';
import {getCuisineUrl} from '../../store/urlManagement';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getVenueVegoStatus} from '../../store/vegoStatus';
import {DetailedCuisines} from '../../store/getMergedDetailedCuisinesSelector';
import {assertUnreachable} from '../../utilities/misc-utilities';

type Scrollable = 'whole' | 'content' | 'none';

function scrollablePropToOverflow(scrollable: Scrollable): {root: Property.Overflow, content: Property.Overflow} {
    if (scrollable === 'whole') {
        return {
            root: 'scroll',
            content: 'hidden',
        };
    } else if (scrollable === 'content') {
        return {
            root: 'hidden',
            content: 'scroll',
        };
    }

    return {
        root: 'hidden',
        content: 'hidden',
    };
}

const useStyles = makeStyles<DefaultTheme, StyleProps>((theme: Theme) =>
    createStyles({
        root: (props) => ({
            display: 'flex',
            flexDirection: 'column',
            height:'100%',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            overflow: scrollablePropToOverflow(props.scrollable).root,
        }),
        list: (props) => ({
            overflow: scrollablePropToOverflow(props.scrollable).content,
            overscrollBehavior: 'none'
        }),
        mainHeader: {
            marginBottom: 0,
        },
        subHeader: {
            fontWeight: 100,
            color: 'grey',
        },
        blurb: {
            display: 'block',
        },
        venueHeader: {
            paddingBottom: 5,
            paddingLeft: 16,
            paddingRight: 16,
        },
        topTitleArea: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        shareIcon: {
            display: 'flex',
            flexDirection: 'column',
        },
        shareIconInner: {
            padding: 0,
        },
        shareAvatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        attributions: {
        },
        icons: {
            verticalAlign: 'middle',
            marginRight: 3,
        }
    }),
);

type Props = {
    className: string | undefined,
    venue: VenueResponse,
    detailedCuisines: DetailedCuisines,
    scrollable: Scrollable,
    onContentScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void,
}

function priceLevelToDollarSigns(priceLevel: PriceLevel): string {
    if (priceLevel === 0) {
        return 'free';
    }

    return '$'.repeat(priceLevel);
}

function makePriceLevelString(priceLevel: PriceLevel | undefined): string {
    if (priceLevel === undefined) {
        return '';
    }
    return ' - ' + priceLevelToDollarSigns(priceLevel);
}

function vegeStatusToDescription(status: VegoStatus): string {
    switch (status) {
    case NewVegoStatus.Bad:
        return 'Terrible for vegetarians';
    case NewVegoStatus.Struggle:
        return 'A limited experience for vegetarians';
    case NewVegoStatus.Medium:
        return 'Alright for vegetarians';
    case NewVegoStatus.Easy:
        return 'Good for vegetarians';
    case NewVegoStatus.WholeMenu:
        return 'A whole menu of vegetarian options';
    case DeprecatedVegoStatus.Meater:
        return 'Terrible for vegetarians';
    case DeprecatedVegoStatus.VeganFriendly:
    case DeprecatedVegoStatus.VeganOnly:
    case DeprecatedVegoStatus.VegetarianFriendly:
    case DeprecatedVegoStatus.VegetarianOnly:
        return 'Good for vegetarians';

    default:
        assertUnreachable(status);
    }
}

function VenueOpeningHoursSection({venue, parentKey}: {venue: VenueResponse, parentKey: string}): JSX.Element | null {
    const [expanded, setExpanded] = useState(false);
    if (!venue.openingHours) {
        return null;
    }

    const currentDate = new Date();

    // Because the language parameter is set to australia, weekday_text[0] corresponds to Monday.
    // If we introduce internationalisation, this may change.
    const fullHoursText = venue.openingHours.weekday_text.map(day => (<>{day} <br/></>));
    // Convert week beginning on Sunday to week beginning on Monday.
    const currentDay = (currentDate.getDay() + 6) % 7;
    const todaysHoursText = venue.openingHours.weekday_text[currentDay];

    const hoursText = expanded ? fullHoursText : todaysHoursText;

    return (<>
        <Divider light key={parentKey + 'divider'}/>
        <ListItem button key={parentKey + 'item'} onClick={() => {setExpanded(!expanded);}}>
            <ListItemText key={parentKey + 'innerItem'} secondary={hoursText} />
            {expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </ListItem>
    </>);
}

interface StyleProps {
    scrollable: Scrollable
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function defaultOnContentScroll() {}

function VenueDrawerContentComponent({
    className, 
    venue, 
    scrollable,
    detailedCuisines,
    onContentScroll = defaultOnContentScroll
}: Props): JSX.Element {
    const classes = useStyles({scrollable});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const venueName = venue.name;
    const priceLevel = venue.priceLevel;

    const rawBlurb = venue.multiLineBlurb;

    const unsafeBlurbHtml: string | undefined = rawBlurb && marked(rawBlurb);
    const safeBlurbHtml: string | undefined = unsafeBlurbHtml && DOMPurify.sanitize(unsafeBlurbHtml);
    const blurbReactEls: string | JSX.Element | JSX.Element[] | undefined = safeBlurbHtml && parse(safeBlurbHtml);
    const primaryCuisine = venue.cuisines[0];
    const cuisineString = primaryCuisine.secondary ?
        primaryCuisine.primary + ' - ' + primaryCuisine.secondary :
        primaryCuisine.primary;
    const shortCuisineString = primaryCuisine.secondary ?
        primaryCuisine.secondary : primaryCuisine.primary;
    const [copiedLinkToastOpen, setCopiedLinkToastOpen] = useState(false);

    function onShareClicked(): void {
        const shareData = {
            title: 'Have you eaten - ' + shortCuisineString + ' - ' + venueName,
            text: venueName + ' - ' + singleLineBlurb,
            url: window.location.href,
        };
        if (navigator.share) {
            navigator.share(shareData).catch((e) => {
                console.error('navigator.share had an issue: ' + e, e);
            });
        } else {
            console.info('Native share not supported, falling back to copy paste');
            const result = copy(shareData.url);

            if (result) {
                setCopiedLinkToastOpen(true);
            }
        }
    }

    const innerAttributions = venue.attributions.map((htmlString, index) => {
        const safeHtmlString = DOMPurify.sanitize(htmlString);
        const reactEl = parse(safeHtmlString);
        return (<div key={'attribution-' + index}>{reactEl}<br/></div>);
    });

    const attributionEl = innerAttributions.length > 0 ? [
        (<ListItem button key="attributions" className={classes.attributions}>
            {innerAttributions}
        </ListItem>),
        (<Divider light key="postAttributionDivider" />)
    ] : undefined;

    const websiteEl = venue.website ? [
        (<ListItem button key="website">
            <a href={venue.website} target="_blank" rel="noopener noreferrer">
                <PublicIcon color="primary" className={classes.icons}/>
                {new URL(venue.website).hostname}
            </a>
        </ListItem>),
        (<Divider light key="postWebsiteDivider" />)
    ] : undefined;

    const vegoStatus = getVenueVegoStatus(venue, detailedCuisines);

    const vegoStatusEl = vegoStatus ? [
        (<ListItem button key="website">
            <Typography>
                {vegeStatusToDescription(vegoStatus)}
            </Typography>
        </ListItem>),
        (<Divider light key="postVegoStatusDivider" />)
    ] : undefined;

    const phoneNumberEl = venue.localPhoneNumber ? [
        (<ListItem button key="phoneNumber">
            <PhoneIcon color="primary" className={classes.icons}/>
            <a href={'tel:' + (venue.internationalPhoneNumber || venue.localPhoneNumber)}>{venue.localPhoneNumber}</a>
        </ListItem>),
        (<Divider light key="postPhoneNumberDivider" />)
    ] : undefined;

    const priceLevelString = makePriceLevelString(priceLevel);
    const singleLineBlurb = venue.singleLineBlurb;

    const venueTypesForDisplay = (venue.type || []).slice(0, 3);

    const venueTypesForDisplayWithFallback = venueTypesForDisplay.length === 0 ? ['Restaurant'] : venueTypesForDisplay;

    const venueTypesString = venueTypesForDisplayWithFallback.reduce((prev, cur) => {
        return prev + ', ' + cur;
    });
    
    const addressElement = venue.googleUrl ?
        <a href={venue.googleUrl} target="_blank" rel="noopener noreferrer">{venue.simpleAddress}</a> :
        venue.simpleAddress;

    return (
        <div className={[classes.root, className].join(' ')}>
            <header className={classes.venueHeader}>
                <div className={classes.topTitleArea}>
                    <h1 className={classes.mainHeader}>{venueName}</h1>
                    <div className={classes.shareIcon} onClick={onShareClicked}>
                        <IconButton
                            color="primary"
                            aria-label="share"
                            className={classes.shareIconInner}
                            size="large">
                            {isIOS()? <IosShareIcon/> : <AndroidShareIcon/>}
                        </IconButton>
                        Share
                    </div>
                </div>
                <div role="doc-subtitle" className={classes.subHeader}>{singleLineBlurb}</div>
            </header>


            <List component="div" className={classes.list} aria-label="venue details" onScroll={onContentScroll}>
                <Divider light key="divider1"/>
                <ListItem button key="address">
                    <ListItemText key="innerAddress" secondary={addressElement} />
                </ListItem>
                <VenueOpeningHoursSection venue={venue} key="hours" parentKey="hours"/>
                <Divider light key="divider2"/>
                <ListItem button key="infoIcons" onClick={async () => {
                    dispatch(openCuisineDrawer());
                    await navigate(getCuisineUrl(primaryCuisine));
                }}>
                    <ListItemText key="infoIconText" secondary={cuisineString + ' ' + venueTypesString.toLowerCase() + priceLevelString} />
                </ListItem>
                <Divider light key="divider3"/>
                {websiteEl}
                {phoneNumberEl}
                {vegoStatusEl}
                {blurbReactEls && <ListItem button key="blurb" className={classes.blurb}>
                    {blurbReactEls}
                </ListItem>}
                <Divider light key="divider4" />
                {attributionEl}
            </List>
            <Snackbar
                open={copiedLinkToastOpen}
                autoHideDuration={6000}
                onClose={() => {setCopiedLinkToastOpen(false);}}
            >
                <MuiAlert elevation={6} variant="filled" onClose={() => {setCopiedLinkToastOpen(false);}} severity="info">
                    We copied this venue to your clipboard. 🤙
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

const VenueDrawerContent = React.memo(VenueDrawerContentComponent, (prevProps, newProps) => {
    return prevProps.venue === newProps.venue && prevProps.scrollable === newProps.scrollable;
});

export {
    VenueDrawerContent
};