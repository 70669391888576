import React from 'react';
import { ListItem, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {useDispatch} from 'react-redux';
import {allFiltersCleared, cuisineSelected} from '../../store/venuesSlice';
import makeStyles from '@mui/styles/makeStyles';
import {searchResultsClosed} from '../../store/uiSlice';
import RoomIcon from '@mui/icons-material/Room';
import {fitMapToLocation, LocationSearchResult} from '../../store/searchEngines/location';

interface Props {
    searchResult: LocationSearchResult,
    className?: string,
}

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            justifyContent: 'space-between',
        },
        innerText: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            margin: 2,
            marginRight: 8,
        }
    }),
);

function LocationBasedSearchResult({searchResult, className}: Props): JSX.Element {
    const dispatch = useDispatch();
    const classes = useStyles();

    return <ListItem
        button
        className={[classes.listItem, className].join(' ')}
        key={searchResult.item.value}
        onClick={() => {
            dispatch(allFiltersCleared());
            dispatch(cuisineSelected({
                cuisine: null
            }));
            dispatch(fitMapToLocation(searchResult.item.value));
            dispatch(searchResultsClosed());
        }}
        data-search-score={searchResult.score}
    >
        <div className={classes.innerText}>
            <RoomIcon className={classes.icon} color={'secondary'}/>
            <Typography noWrap>
                {searchResult.item.value}
            </Typography>
        </div>
    </ListItem>;
}

export {
    LocationBasedSearchResult,
};