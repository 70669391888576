import React from 'react';
import { Divider, ListItem, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import {useDispatch} from 'react-redux';
import {cuisineSelected} from '../../store/venuesSlice';
import makeStyles from '@mui/styles/makeStyles';
import {cuisinesListClosed, searchResultsClosed} from '../../store/uiSlice';
import FlagIcon from '@mui/icons-material/Flag';
import {GroupedCuisineSearchResult} from '../../store/searchEngines/cuisines';
import {getCuisineUrl} from '../../store/urlManagement';
import {useNavigate} from 'react-router-dom';

interface Props {
    searchResult: GroupedCuisineSearchResult,
    className?: string
}

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            justifyContent: 'space-between',
        },
        secondaryListItem: {
            justifyContent: 'space-between',
            marginLeft: 20,
            borderLeft: '1px solid gray',
            marginBottom: -1,
        },
        innerText: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            margin: 2,
            marginRight: 8,
        },
        insetDivider: {
            marginLeft: 52
        }
    }),
);

function CuisineBasedSearchResult({searchResult, className}: Props): JSX.Element {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    const secondaryCuisineEls = searchResult.secondaries.map((secondaryCuisineSearchResult) => {
        return <React.Fragment key={secondaryCuisineSearchResult.key}>
            <ListItem
                button
                className={[classes.secondaryListItem, className].join(' ')}
                onClick={async () => {
                    dispatch(cuisineSelected({
                        cuisine: secondaryCuisineSearchResult.item
                    }));
                    dispatch(searchResultsClosed());
                    dispatch(cuisinesListClosed());
                    await navigate(getCuisineUrl(secondaryCuisineSearchResult.item));
                }}
                data-search-score={secondaryCuisineSearchResult.score}
            >
                <div className={classes.innerText}>
                    <FlagIcon className={classes.icon} color={'secondary'}/>
                    <Typography noWrap>
                        {secondaryCuisineSearchResult.item.secondary}
                    </Typography>
                </div>
            </ListItem>
            <Divider
                light
                classes={{inset: classes.insetDivider}}
                variant="inset"
                component="li"
            />
        </React.Fragment>;
    });

    return <React.Fragment key={searchResult.key}>
        <ListItem
            button
            className={[classes.listItem, className].join(' ')}
            onClick={async () => {
                dispatch(cuisineSelected({
                    cuisine: searchResult.item
                }));
                dispatch(searchResultsClosed());
                dispatch(cuisinesListClosed());
                await navigate(getCuisineUrl(searchResult.item));
            }}
            data-search-score={searchResult.score}
        >
            <div className={classes.innerText}>
                <FlagIcon className={classes.icon} color={'secondary'}/>
                <Typography noWrap>
                    {searchResult.item.primary}
                </Typography>
            </div>
        </ListItem>
        <Divider
            key={searchResult.item.primary + 'none' + '-divider'}
            classes={{inset: classes.insetDivider}}
            light
            variant="inset"
            component="li"
        />
        {secondaryCuisineEls}
    </React.Fragment>;
}

export {
    CuisineBasedSearchResult,
};