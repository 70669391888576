import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from './store';
import {LatLngLiteral} from '@googlemaps/google-maps-services-js';

type MapAction = ExpandToIncludeClosest | ShowAll | CenterAndZoomToSingle | TrackUserLocation;

interface MapControlState {
    currentAction?: MapAction,
    uiOverlayEnabled: boolean
}

const initialState: MapControlState = {
    currentAction: {type: 'trackUserLocation'},
    uiOverlayEnabled: true
};

interface ExpandToIncludeClosest {
    type: 'expandToIncludeClosest',
    locations: LatLngLiteral[]
}

interface ShowAll {
    type: 'showAll',
    locations: LatLngLiteral[]
}

interface TrackUserLocation {
    type: 'trackUserLocation',
}

interface CenterAndZoomToSingle {
    type: 'centerAndZoomToSingle',
    location: LatLngLiteral
}

const mapControlSlice = createSlice({
    name: 'mapControl',
    initialState,
    reducers: {
        uiOverlayEnabled: (state) => {
            state.uiOverlayEnabled = true;
        },
        uiOverlayDisabled: (state) => {
            state.uiOverlayEnabled = false;
        },
        mapActionTriggered: (state, mapAction: PayloadAction<MapAction>) => {
            state.currentAction = mapAction.payload;
        },
        mapActionCompleted: (state) => {
            state.currentAction = undefined;
        },
    },
});

const {
    mapActionTriggered,
    mapActionCompleted: innerMapActionCompleted,
    uiOverlayDisabled,
    uiOverlayEnabled
} = mapControlSlice.actions;

const mapActionCompleted = (): AppThunk => (dispatch, getState) => {
    const currentAction = getCurrentMapActionSelector(getState());
    if (currentAction) {
        dispatch(innerMapActionCompleted());
    }
};

const mapControlReducer = mapControlSlice.reducer;

const getCurrentMapActionSelector: (state: RootState) => MapAction | undefined =
    (state: RootState) => state.mapControl.currentAction;

const getUiOverlayEnabledSelector: (state: RootState) => boolean = (state: RootState) =>
    state.mapControl.uiOverlayEnabled;

export {
    mapControlReducer,
    mapActionCompleted,
    mapActionTriggered,
    getCurrentMapActionSelector,
    uiOverlayDisabled,
    uiOverlayEnabled,
    getUiOverlayEnabledSelector
};
