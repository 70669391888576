import {RootState} from './store';
import {isVenueOpenNow} from '../utilities/openNowCalculator';
import {assertUnreachable, getSydneyUtcOffsetInMinutes, matchEnum} from '../utilities/misc-utilities';
import {createSelector} from 'reselect';
import {
    Cuisine,
    DetailedCuisine,
    NewVegoStatus,
    VenueResponse
} from '../shared-types/responses';
import {getMergedDetailedCuisinesSelector} from './getMergedDetailedCuisinesSelector';
import {getVenueVegoStatus} from './vegoStatus';

export interface SearchFilterParent {
    type: string,
    id: number,
    // How filters of the same type are combined.
    combinerLogic: 'OR' | 'AND',
}

interface OpenNow extends SearchFilterParent {
    type: 'Open Now',
    combinerLogic: 'AND',
    // There's only ever one open now filter active at a time.
    atTime: number,
    id: 0,
}

export function getOpenNowFilter(timestamp: number): OpenNow {
    return {
        type: 'Open Now',
        combinerLogic: 'AND',
        id: 0,
        atTime: timestamp,
    };
}

export function getVegetarianFriendlyFilter(minVegoStatus: keyof typeof VegetarianFriendlyScore, includeUnrated: boolean): VegetarianFriendlyFilter {
    return {
        type: 'Vegetarian Friendly',
        combinerLogic: 'AND',
        id: 0,
        vegoScore: VegetarianFriendlyScore[minVegoStatus],
        includeUnrated,
    };
}

enum VegetarianFriendlyScore {
    Bad = 0, // DEFAULT, same as no filter applied,
    Struggle = 1,
    Medium = 2,
    Easy = 3,
    WholeMenu = 4,
}

interface VegetarianFriendlyFilter extends SearchFilterParent {
    type: 'Vegetarian Friendly',
    combinerLogic: 'AND',
    vegoScore: VegetarianFriendlyScore,
    includeUnrated: boolean,
    id: 0, // Only one vego score can be applied at one time. 
    // Applying a vego score of 1 means 1 and higher, 
}

export type VenueType = string;

interface VenueTypeFilter extends SearchFilterParent {
    type: 'VenueType',
    combinerLogic: 'OR',
    venueType: VenueType,
    id: number,
}

let typeFilterUniqueID = 0;

export const getOrCreateVenueTypeFilter: (venueType: VenueType) => (state: RootState) => VenueTypeFilter = (venueType => {
    return (rootState) => {
        const existingFilter: VenueTypeFilter | undefined = rootState.mapData.searchFilters.find((existingFilter: SearchFilter) => {
            return existingFilter.type === 'VenueType' && existingFilter.venueType === venueType.toLowerCase();
        }) as VenueTypeFilter | undefined;

        const result: VenueTypeFilter = {
            combinerLogic: 'OR',
            id: typeFilterUniqueID,
            type: 'VenueType',
            venueType: venueType.toLowerCase()
        };
        typeFilterUniqueID++;

        return existingFilter || result;
    };
});

function searchFilterTypeToFunction(filter: SearchFilter, detailedCuisines: Map<string, (Cuisine & Partial<DetailedCuisine>)>): (venue: VenueResponse) => boolean {
    switch (filter.type) {
    case 'VenueType':
        return venueTypeFilterFunction(filter.venueType);
    case 'Open Now':
        return openNowFilterFunction(filter.atTime);
    case 'Vegetarian Friendly':
        return vegetarianFriendlyFilterFunction(filter, detailedCuisines);
    default:
        assertUnreachable(filter);
    }
}

function openNowFilterFunction(atTime: number): (venue: VenueResponse) => boolean {
    return (venue: VenueResponse) => {
        if (venue.openingHours) {
            return isVenueOpenNow({
                opening_hours: venue.openingHours,
                utc_offset: getSydneyUtcOffsetInMinutes(atTime)
            }, atTime);
        }
        return false;
    };
}

function vegetarianFriendlyFilterFunction(vegoFilter: VegetarianFriendlyFilter, detailedCuisines: Map<string, (Cuisine & Partial<DetailedCuisine>)>): (venue: VenueResponse) => boolean {
    return (venue: VenueResponse) => {
        const vegoScoreString = matchEnum(getVenueVegoStatus(venue, detailedCuisines), NewVegoStatus);

        const vegoScore = vegoScoreString && VegetarianFriendlyScore[vegoScoreString];

        if (!vegoScore) {
            if (vegoFilter.includeUnrated) {
                return true;
            } else {
                return VegetarianFriendlyScore.Bad >= vegoFilter.vegoScore;
            }
        } else {
            return vegoScore >= vegoFilter.vegoScore;
        }
    };
}

function venueTypeFilterFunction(venueType: VenueType): (venue: VenueResponse) => boolean {
    return (venue: VenueResponse) => {
        return !!venue.type?.map((type) => type.toLowerCase()).includes(venueType.toLowerCase());
    };
}

export const getSearchFiltersSelector: (state: RootState) => SearchFilter[] | null = (state: RootState) => state.mapData.searchFilters;

function searchFiltersOfSameTypeToFunction(cur: SearchFilter[], detailedCuisines: Map<string, (Cuisine & Partial<DetailedCuisine>)>): (venue: VenueResponse) => boolean {
    if (cur[0].combinerLogic === 'AND') {
        return cur.reduce((acc, cur) => {
            return (venue) => {
                return acc(venue) && searchFilterTypeToFunction(cur, detailedCuisines)(venue);
            };
        }, (_: VenueResponse) => true);
    } else {
        return cur.reduce((acc, cur) => {
            return (venue) => {
                return acc(venue) || searchFilterTypeToFunction(cur, detailedCuisines)(venue);
            };
        }, (_: VenueResponse) => false);
    }
}

export const getCombinedFilters = createSelector(
    [getSearchFiltersSelector, getMergedDetailedCuisinesSelector],
    (searchFilters: SearchFilter[] | null, detailedCuisines: Map<string, (Cuisine & Partial<DetailedCuisine>)>) => {
        const searchFilterMapByType = (searchFilters || []).reduce((acc, cur) => {
            const newEntry = [...(acc.get(cur.type) || []), cur];
            acc.set(cur.type, newEntry);
            return acc;
        }, new Map<VenueType, SearchFilter[]>());
        
        return [...searchFilterMapByType.values()].reduce((acc, cur) => {
            return (venue) => searchFiltersOfSameTypeToFunction(cur, detailedCuisines)(venue) && acc(venue);
        }, (_venue: VenueResponse) => true);
    }
);

export const filterActiveSelector: (state: RootState) => (filter: SearchFilterParent) => boolean | null = (state: RootState) =>
    (filter) => state.mapData
        .searchFilters
        .filter((innerFilter: SearchFilter) =>
            innerFilter.type === filter.type && innerFilter.id === filter.id
        ).length > 0;

export const anyFilterActiveSelector: (state: RootState) => boolean = (state: RootState) =>
    state.mapData
        .searchFilters
        .length > 0;

export const activeFilterCountSelector: (state: RootState) => number = (state: RootState) =>
    state.mapData
        .searchFilters
        .length;

export type SearchFilter = OpenNow | VenueTypeFilter | VegetarianFriendlyFilter