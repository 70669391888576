import React, {useState} from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
    Button, Checkbox, Chip,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Switch,
    Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch, useSelector} from 'react-redux';
import {
    addFilter, getSearchedCuisineSelector,
    getVenueSearchResultCount,
    removeFilter,
    venueTypeFilterAdded, venueTypeFilterRemoved
} from '../../store/venuesSlice';
import Divider from '@mui/material/Divider';
import MuiDialogTitle from '@mui/material/DialogTitle';
import {
    filterActiveSelector,
    getOpenNowFilter,
    getOrCreateVenueTypeFilter,
    getVegetarianFriendlyFilter,
} from '../../store/searchFilters';
import {useNavigate} from 'react-router-dom';
import {AdvancedVegoDialog} from './AdvancedVegoDialog';
import {NewVegoStatus} from '../../shared-types/responses';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: () => ({
            display: 'flex',
            flexDirection: 'column',
            height:'100%',
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        }),
        title: {
            fontSize: '1.1rem',
            lineHeight: '1.9',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        bottomSection: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: theme.spacing(2),
        },
        venueCount: {
            textAlign: 'right',
        },
        middleSection: {
            flexGrow: 1,
        },
        form: {
            margin: theme.spacing(3),
            marginBottom: theme.spacing(1),
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            display: 'flex',
        },
        vegoFriendlySection: {
            display: 'flex',
            alignItems: 'center',
        },
        formControl: {
            margin: theme.spacing(3),
            marginTop: theme.spacing(1)
        },
    }),
);

type Props = {
    className: string | undefined,
    isSidebar: boolean,
}

function FilterBoxContent({className, isSidebar}: Props): JSX.Element {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isFilterActive = useSelector(filterActiveSelector);
    const openNowFilterActive = isFilterActive(getOpenNowFilter(Date.now())) || false;
    const vegoFriendlyFilterActive = isFilterActive(getVegetarianFriendlyFilter('Easy', false)) || false;
    const venueSearchResultCount = useSelector(getVenueSearchResultCount);
    const currentCuisine = useSelector(getSearchedCuisineSelector);
    const currentCuisineText = (currentCuisine?.secondary || currentCuisine?.primary)?.replace(/ *\([^)]*\) */g, '');
    const [isVegoAdvancedDialogOpen, setVegoAdvancedDialogOpen] = useState(false);

    function onFilterByTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const venueType = event.target.name;
        if (event.target.checked) {
            dispatch(venueTypeFilterAdded(venueType));
        } else {
            dispatch(venueTypeFilterRemoved(venueType));
        }
    }

    const filterBoxes = [
        'Restaurant',
        'Cafe',
        'Bakery',
        'Sweet shop',
        'Takeaway joint',
        'Deli',
        'Community club',
        'Grocer'
    ].map((venueType) => {
        const venueTypeFilter = useSelector(getOrCreateVenueTypeFilter(venueType));
        return <FormControlLabel
            key={venueType}
            control={<Checkbox checked={isFilterActive(venueTypeFilter) || false} onChange={onFilterByTypeChange} name={venueType} />}
            label={venueType}
        />;
    });

    function handleClose() {
        return navigate('/');
    }

    const handleOpenNowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            dispatch(addFilter({filter: getOpenNowFilter(Date.now())}));
        } else {
            dispatch(removeFilter({filter: getOpenNowFilter(Date.now())}));
        }
    };

    const handleVegoFriendlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            dispatch(addFilter({filter: getVegetarianFriendlyFilter('Easy', false)}));
        } else {
            dispatch(removeFilter({filter: getVegetarianFriendlyFilter('Easy', false)}));
        }
    };

    const onVegoDialogClose = (vegoScore: NewVegoStatus, includeUnrated: boolean) => {
        dispatch(removeFilter({filter: getVegetarianFriendlyFilter(vegoScore, includeUnrated)}));
        dispatch(addFilter({filter: getVegetarianFriendlyFilter(vegoScore, includeUnrated)}));

        setVegoAdvancedDialogOpen(false);
    };

    const vegoAdvancedClicked = () => {
        setVegoAdvancedDialogOpen(!isVegoAdvancedDialogOpen);
    };

    return (
        <div className={[classes.root, className].join(' ')}>
            <div>
                <MuiDialogTitle>
                    <Typography className={classes.title} variant="h6">
                    Filters
                    </Typography>
                    {isSidebar && <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                        size="large">
                        <CloseIcon />
                    </IconButton> }
                </MuiDialogTitle>
                <Divider/>
            </div>
            <div className={classes.middleSection}>
                <FormGroup row className={classes.form}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={openNowFilterActive}
                                onChange={handleOpenNowChange}
                                name="openNow"
                                color="primary"
                            />
                        }
                        label="Open Now"
                    />
                    <div className={classes.vegoFriendlySection}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={vegoFriendlyFilterActive}
                                    onChange={handleVegoFriendlyChange}
                                    name="vegoFriendly"
                                    color="primary"
                                />
                            }
                            label="Vegetarian Friendly"
                        />
                        <Chip size="small" label="Advanced" onClick={vegoAdvancedClicked} />
                        <AdvancedVegoDialog
                            open={isVegoAdvancedDialogOpen}
                            onClose={onVegoDialogClose}
                        />
                    </div>
                </FormGroup>

                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">What kind of place?</FormLabel>
                    <FormGroup row={true}>
                        {filterBoxes}
                    </FormGroup>
                </FormControl>
            </div>
            <div>
                <Divider/>
                <div className={classes.bottomSection}>
                    {!isSidebar && <Button size={'small'} variant="contained" color="primary" onClick={handleClose}>
                        Show {venueSearchResultCount} {currentCuisineText ? currentCuisineText + ' ' : '' }{venueSearchResultCount === 1 ? 'venue' : 'venues'}
                    </Button> }
                    {isSidebar && <Typography className={classes.venueCount}>
                        {venueSearchResultCount} {venueSearchResultCount === 1 ? 'venue' : 'venues'}
                    </Typography>}
                </div>
            </div>
        </div>
    );
}

export {
    FilterBoxContent
};