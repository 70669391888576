import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from './store';
import {sendAmplitudeData} from '../utilities/amplitude';
import {getCuisineUrl, getLegacyQueryParams,} from './urlManagement';
import Immutable, {Set as ImmutableSet} from 'immutable';

import venueJSON from '../cms-data/venues.json';
import cuisineJSON from '../cms-data/cuisines.json';

import {
    getCombinedFilters,
    getOrCreateVenueTypeFilter,
    SearchFilter,
    SearchFilterParent,
    VenueType
} from './searchFilters';
import {cuisineEquals, selectRandom} from '../utilities/misc-utilities';
import {mapActionTriggered} from './mapControlSlice';
import {AllVenuesResponse, Cuisine, CuisineResponse, DetailedCuisine, VenueResponse} from '../shared-types/responses';
import {push} from 'redux-first-history';

interface CuisineSelectedSearchResult {
    cuisine: Cuisine
}
interface TagSelectedSearchResult {
    tag: string
}

type SelectedSearchResult = CuisineSelectedSearchResult | TagSelectedSearchResult | undefined

interface VenuesState {
    venues: VenueResponse[] | null;
    venueMap: Record<string, VenueResponse>;
    cuisines: Cuisine[] | undefined;
    cuisineDetails: DetailedCuisine[];
    cuisineDrawerOpen: boolean;
    loadTime: number;
    selectedSearchResult: SelectedSearchResult;
    searchFilters: SearchFilter[];
}

function getInitialCuisine(): Cuisine | null {
    const {cuisine} = getLegacyQueryParams();
    if (cuisine) {
        sendAmplitudeData('cuisineLoadedFromQueryParams', {cuisine});
        return cuisine;
    } else {
        return null;
    }
}

const initialLoad = processAllVenuesResponse(venueJSON as unknown as AllVenuesResponse);

const detailedCuisines: DetailedCuisine[] = cuisineJSON as unknown as DetailedCuisine[];

const legacyInitialCuisine = getInitialCuisine();

export const initialVenueState: VenuesState = {
    cuisines: initialLoad.cuisines,
    cuisineDetails: detailedCuisines,
    venues: initialLoad.venues,
    venueMap: initialLoad.venueMap,
    cuisineDrawerOpen: true,
    loadTime: initialLoad.loadTime || 0,
    selectedSearchResult: legacyInitialCuisine && {cuisine: legacyInitialCuisine} || undefined,
    searchFilters: [],
};

interface ReceiveVenuesAction {
    venues: VenueResponse[],
    cuisines: Cuisine[],
    loadTime?: number,
}

interface ReceiveDetailedCuisinesAction {
    cuisines: DetailedCuisine[],
}

interface AddFilterAction {
    filter: SearchFilter
}

interface RemoveFilterAction {
    filter: SearchFilterParent
}

export const venuesSlice = createSlice({
    name: 'venues',
    initialState: initialVenueState,
    reducers: {
        addFilter: (state, action: PayloadAction<AddFilterAction>) => {
            state.searchFilters.push(action.payload.filter);
        },
        removeFilter: (state, action: PayloadAction<RemoveFilterAction>) => {
            state.searchFilters = state.searchFilters.filter((filter) => {
                return !(filter.type === action.payload.filter.type && filter.id === action.payload.filter.id);
            });
        },
        allFiltersCleared: (state) => {
            state.searchFilters = [];
        },
        closeCuisineDrawer: (state) => {
            state.cuisineDrawerOpen = false;
        },
        openCuisineDrawer: (state) => {
            state.cuisineDrawerOpen = true;
        },
        receiveVenues: (state, action: PayloadAction<ReceiveVenuesAction>) => {
            state.loadTime = action.payload.loadTime || 0;
            state.venues = action.payload.venues;
            state.venueMap = Object.fromEntries(action.payload.venues.map((venue) =>
                [venue.googlePlaceId, venue]
            ));
            state.cuisines = action.payload.cuisines;
        },
        receiveDetailedCuisines: (state, action: PayloadAction<ReceiveDetailedCuisinesAction>) => {
            state.cuisineDetails = action.payload.cuisines;
        },
        selectSearchResult: (state, action: PayloadAction<SelectedSearchResult>) => {
            state.selectedSearchResult = action.payload;
        },
    },
});

const { receiveDetailedCuisines } = venuesSlice.actions;
export const receiveVenuesForTesting = venuesSlice.actions.receiveVenues;

export const allFiltersCleared = venuesSlice.actions.allFiltersCleared;

interface ProcessedAllVenuesResponse {
    loadTime: number | undefined;
    venueMap: Record<string, VenueResponse>;
    venues: VenueResponse[];
    cuisines: Cuisine[]
}

function processAllVenuesResponse(allVenuesResponse: AllVenuesResponse): ProcessedAllVenuesResponse {
    const cuisinesISet = ImmutableSet<Cuisine>(Immutable.fromJS(allVenuesResponse.venues.flatMap(venue => venue.cuisines)));
    const cuisines = Array.from(
        cuisinesISet.toJS()
    ).sort((cuisineA, cuisineB) => {
        const sortByPrimary = cuisineA.primary.localeCompare(cuisineB.primary);
        if (sortByPrimary !== 0) {
            return sortByPrimary;
        }

        const secondaryCuisineA = cuisineA.secondary || '';
        const secondaryCuisineB = cuisineB.secondary || '';

        return secondaryCuisineA.localeCompare(secondaryCuisineB);
    });

    const venueMap: Record<string, VenueResponse> = Object.fromEntries(allVenuesResponse.venues.map((venue) => [venue.googlePlaceId, venue]));

    return {cuisines, venues: Object.values(venueMap), loadTime: allVenuesResponse.loadTime, venueMap};
}

export const fetchCuisines = (): AppThunk => async (dispatch, _getState) => {
    try {
        const cuisinesResponse = cuisineJSON as unknown as CuisineResponse;

        dispatch(receiveDetailedCuisines({
            cuisines: cuisinesResponse,
        }));
    } catch(e) {
        console.error('Failed to fetch cuisine details from the backend');
    }
};


export const getVenueMapSelector: (state: RootState) => Record<string, VenueResponse> = (state: RootState) =>
    state.mapData.venueMap;

export const getCuisineDrawerOpenSelector: (state: RootState) => boolean = (state: RootState) => state.mapData.cuisineDrawerOpen;
export const getLoadTimeSelector: (state: RootState) => number = (state: RootState) => state.mapData.loadTime;
export const getAllVenuesSelector: (state: RootState) => VenueResponse[] | null = (state: RootState) => state.mapData.venues;

export const getSearchedCuisineSelector: (state: RootState) => Cuisine | null = (state: RootState) =>
    state.mapData.selectedSearchResult?.cuisine || null;

export const getTagSelector: (state: RootState) => string | undefined = (_state: RootState) => {
    return _state.mapData.selectedSearchResult?.tag;
};

export const getVenueSearchResults = createSelector(
    [getCombinedFilters, getSearchedCuisineSelector, getAllVenuesSelector, getTagSelector],
    (combinedFilters, searchedCuisine, allVenues, currentTag) => {
        return allVenues
            ?.filter((venue) => {
                return searchedCuisine === null ||
                venue.cuisines.find(cuisine => cuisine.primary === searchedCuisine.primary && cuisine.secondary === searchedCuisine.secondary) ||
                (venue.cuisines.map(cuisine => cuisine.primary).includes(searchedCuisine.primary) && !searchedCuisine.secondary);
            })
            ?.filter(combinedFilters)
            ?.filter((venue: VenueResponse) =>
                !currentTag || (venue.tags?.map((tag) => 
                    tag.toLowerCase().replace(/[^\w']|_/g, '')) || []
                ).includes(currentTag.toLowerCase().replace(/[^\w']|_/g, ''))
            ) || null;
    }
);

export const getVenueSearchResultCount = createSelector(
    [getVenueSearchResults],
    (searchResults) => {
        return searchResults?.length || 0;
    }
);

export function venueSearchResultsEqualityFn(
    oldResults: VenueResponse[] | null,
    newResults: VenueResponse[] | null
): boolean {
    if (!oldResults && !newResults) {
        return true;
    }

    if (!oldResults || !newResults) {
        return false;
    }

    if (oldResults.length !== newResults.length) {
        return false;
    }

    for (let i = 0; i < oldResults.length; i++) {
        if (oldResults[i] !== newResults[i]) {
            return false;
        }
    }

    return true;
}

const innerSelectSearchResult = venuesSlice.actions.selectSearchResult;
const addFilterInternal = venuesSlice.actions.addFilter;
export const openCuisineDrawer = venuesSlice.actions.openCuisineDrawer;
export const closeCuisineDrawer = venuesSlice.actions.closeCuisineDrawer;

export const addFilter = (action: AddFilterAction): AppThunk => dispatch => {
    dispatch(addFilterInternal(action));
    sendAmplitudeData('FilterAdded', {filter: action.filter});
};

export const removeFilter = (action: RemoveFilterAction): AppThunk => dispatch => {
    dispatch(removeFilterInternal(action));
    sendAmplitudeData('FilterRemoved', {filter: action.filter});
};

export const removeFilterInternal = venuesSlice.actions.removeFilter;
export const cuisineSelected = (
    {
        cuisine,
        fitMapToCuisine = false
    }: {
        cuisine: Cuisine | null,
        fitMapToCuisine?: boolean
    }
): AppThunk => async (dispatch) => {
    dispatch(innerSelectSearchResult(cuisine && {cuisine} || undefined));

    if (fitMapToCuisine) {
        dispatch(zoomToExposeAllShownVenues());
    } else {
        dispatch(zoomToExposeClosestShownVenues());
    }

    sendAmplitudeData('selectedCuisine', {cuisine});
};

const zoomToExposeAllShownVenues = (): AppThunk => async (dispatch, getState) => {
    const locations = getVenueSearchResults(getState())?.map((venue: VenueResponse) => (venue.location)) || [];
    dispatch(mapActionTriggered({type: 'showAll', locations}));
};

const zoomToExposeClosestShownVenues = (): AppThunk => async (dispatch, getState) => {
    const locations = getVenueSearchResults(getState())?.map((venue: VenueResponse) => (venue.location)) || [];
    dispatch(mapActionTriggered({type: 'expandToIncludeClosest', locations}));
};

export const tagSelected = (
    {
        tag,
        fitMapToCuisine = false
    }: {
        tag: string | undefined,
        fitMapToCuisine?: boolean
    }
): AppThunk => async (dispatch) => {
    dispatch(innerSelectSearchResult(tag && {tag} || undefined));

    if (fitMapToCuisine) {
        dispatch(zoomToExposeAllShownVenues());
    } else {
        dispatch(zoomToExposeClosestShownVenues());
    }

    sendAmplitudeData('selectedTag', {tag});
};

export const randomCuisineSelected = (): AppThunk => async (dispatch, getState) => {
    const currentSearchedCuisine = getSearchedCuisineSelector(getState());
    const combinedFilters = getCombinedFilters(getState());
    const allVenues = getAllVenuesSelector(getState());
    const filteredVenues = allVenues?.filter(combinedFilters);
    const filteredCuisines: Set<Cuisine> = new Set(
        (filteredVenues || [] as VenueResponse[]).flatMap((venue) => venue.cuisines)
            .filter((cuisine) => {
                return !cuisineEquals(cuisine, currentSearchedCuisine);
            })
    );

    const cuisineToSelect = selectRandom([...filteredCuisines]);
    
    dispatch(push(getCuisineUrl(cuisineToSelect || undefined)));
};

export const venueTypeFilterAdded = (venueType: VenueType): AppThunk => async (dispatch, getState) => {
    const venueTypeFilter = getOrCreateVenueTypeFilter(venueType)(getState());
    dispatch(addFilter({filter: venueTypeFilter}));
    const locationsToExpandTo = getVenueSearchResults(getState())?.map((venue: VenueResponse) => (venue.location));
    dispatch(mapActionTriggered({type: 'expandToIncludeClosest', locations: locationsToExpandTo || []}));
};

export const venueTypeFilterRemoved = (venueType: VenueType): AppThunk => async (dispatch, getState) => {
    const venueTypeFilter = getOrCreateVenueTypeFilter(venueType)(getState());
    dispatch(removeFilter({filter: venueTypeFilter}));
};


export default venuesSlice.reducer;
